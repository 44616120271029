import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import SideBar from './components/sidebar/SideBar';
import Content from './components/content/Content';
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import Functions from './utils/Functions';
import ErrorBoundary from './utils/ErrorBoundary';
import Login from './components/auth/Login';
import Dashboard from './components/admin/Dashboard';

export default () => {
  
  const [isOpen, setOpen] = useState(false);
  const toggle = () => setOpen(!isOpen);
  const cookie = Functions.getCookie('bineza_admin_token');

  return (
    <div>
      <ErrorBoundary>
        <Router>
          <Switch>
            <Route exact path="/" render={() => (
                cookie != "" ? (
                  <Dashboard/>
              ) : (
                <Route exact path="/" component={Login} />
              )
            )}/>
          </Switch>
            {cookie != "" ?
                <Switch>
                  <div className="App wrapper">
                      <SideBar toggle={toggle} isOpen={isOpen}/>
                      <Content toggle={toggle} isOpen={isOpen}/>
                  </div>
                </Switch> :  <Redirect to="/"/>
            }
          </Router>
      </ErrorBoundary>
    </div>
  );
}

