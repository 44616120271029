import React from 'react';
import { BrowserRouter as Router, Route, Switch, Link, withRouter } from 'react-router-dom';
import Functions from '../../../utils/Functions';

export default function TheUser(props) {
  
  const customer_link = `/customer/details/${props.id}`;
  const seller_link = `/seller/details/${props.id}`;
  const fullname = Functions.capitalizeWord(props.firstname+" "+props.lastname);

  return (
    <tr>
        <td>{props.serial}</td>
        <td>
        {props.picture_url ? 
          <span><img src={props.picture_url} alt={fullname} className="image"/> </span> : 
          <span><img src={require('../../images/avatar.png')} alt={fullname} className="image"/></span>
        }
        </td>
        <td>
            {props.type == "seller" ?
              <Link to={seller_link} style={{color:"#17A2B8"}}>{fullname}</Link> :
              <Link to={customer_link} style={{color:"#17A2B8"}}>{fullname}</Link>
          }
        </td>
        <td>
          {Functions.capitalizeFirstLetter(props.type)}
          {props.type == "seller" ? 
            <span> <br/> ({props.business})</span> :
            null
          }
        </td>
        <td>{props.email}</td>
        <td>{props.phone}</td>
        {/* <td>{props.last_seen}</td> */}
        <td>{props.reg_on}</td>
    </tr>
    );
}
