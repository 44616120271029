import React, { useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserCircle, faLock } from '@fortawesome/free-solid-svg-icons';
import { Button, Card, CardBody, CardGroup, Col, Container, Form, Input, InputGroup, InputGroupAddon, InputGroupText, Row } from 'reactstrap';
import axios from 'axios';
import Functions from '../../utils/Functions';
import { serverEndPoint, webTitle, adminToken } from '../../utils/Helpers';
const cookie = Functions.getCookie(adminToken);

export default function Login() {

  const [values, setValues] = React.useState({
    email: '',
    password: '',
  });

  function onChangeHandler(event) {
      const { name, value } = event.target;
      setValues(values => ({...values, [name] : value}));
  }

  function loginAdmin(event) {

      event.preventDefault();
      const status = document.getElementById('login-status');
      const btn = document.getElementById('login-btn');
      
      let { email, password } = values;
      
      if(email == "" || password == "") {
          status.innerHTML = "<p style='color:red'> Incorrect login details </p>";
          return false;
      } else {
          
          Functions.disableBtn(btn);
          let data = `email=${email}&password=${password}`;
          
          axios({
            method: 'post',
            url: `${serverEndPoint}login`,
            data: data,
          })
          .then(response => {
              console.log(response);

              Functions.enableBtn('Sign In', btn);
              
              if(response.data.data.type == 'success') {
                  Functions.setCookie('bineza_admin_token', response.data.data.token, 100);
                  status.innerHTML = `<p style='color:green'>Login successful...</p>`;
                  window.location = "/dashboard";
              } else {
                  status.innerHTML = `<p style='color:red'>${response.data.data.message}</p>`;
              }
          }).
          catch( (error) => {
            Functions.enableBtn('Sign In', btn);
              status.innerHTML = "<p style='color:red'> Invalid request </p>";
          });
      }     
  }

  async function authAdmin() {
    if(cookie != "") {
      window.location="/dashboard";
    }
  }

  useEffect( () => {
      authAdmin();
      document.title = webTitle+" Sign In";
  });

  return (
    <div className="app flex-row align-items-center" style={{marginTop:150}}>
      <Container>
        <Row className="justify-content-center">
          <Col md="5">
            <CardGroup>
              <Card className="p-4">
                <CardBody>
                  <Form method="POST" action="#"  onSubmit={event => loginAdmin(event)}>
                    <div align="center"> <img src={require('../images/logo.png')} width="150"/> </div>
                    <InputGroup className="mb-3">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                        <FontAwesomeIcon icon={faUserCircle} className="mr-2"/>
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input type="text" placeholder="Email address" name="email" onChange={onChangeHandler}/>
                    </InputGroup>
                    <InputGroup className="mb-4">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                        <FontAwesomeIcon icon={faLock} className="mr-2"/>
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input type="password" placeholder="Password" name="password" onChange={onChangeHandler}/>
                    </InputGroup>
                    <Row>
                      <Col xs="12" align="right">
                        <Button color="primary" className="px-4"  id="login-btn">Login</Button>
                        <br/><br/>
                        <div id="login-status" align="center"></div>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </CardGroup>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
