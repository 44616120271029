import axios from 'axios';
export const webTitle = "Bineza Admin | ";
export const adminToken = 'bineza_admin_token';

const endpoint = 'https://server.bineza.com/api/v1/admin/';
export const serverEndPoint = 'https://server.bineza.com/api/v1/admin/';

// const endpoint = 'http://localhost:8000/api/v1/admin/';
// export const serverEndPoint = 'http://localhost:8000/api/v1/admin/';

export const uploadEndpoint = 'https://bineza.com/uploads/uploads';
