import React, {useState, useEffect} from 'react';
import { BrowserRouter as Router, Route, Switch, Link, withRouter } from 'react-router-dom';
import { Button} from 'reactstrap';
import axios from 'axios';
import Functions from '../../../utils/Functions';
import { serverEndPoint, adminToken } from '../../../utils/Helpers';
import swal from 'sweetalert';
const cookie = Functions.getCookie(adminToken);

export default function TheProduct(props) {
  
  const category_link = `/categories/details/${props.category_id}`;
  const product_link = `/ads/details/${props.id}`;
  const seller_link = `/seller/details/${props.seller_id}`;
  const delete_status = `delete-product-status${props.id}`;
  const delete_btn = `delete-product-btn${props.id}`;

  
  async function deleteProduct(product_id) {
    swal({
      title: "",
      text: 'All reviews made on this ads will be deleted as well. \n \n Delete '+props.title+' ?',
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
    .then( async (response) => {
    
      if(response) {
        const status = document.getElementById('delete-product-status'+product_id);
        const btn = document.getElementById('delete-product-btn'+product_id);
          
        if(product_id == "" || !product_id) {
          status.innerHTML = "<p style='color:red'> Kindly select the ads </p>";
          return false;
        } else {
            
          Functions.disableBtn(btn);
          const url = `${serverEndPoint}products/delete/${product_id}`;

          await axios.delete(url, {
            headers : {
              'Accept' : 'application/json',
              'x-access-token' : cookie,
            }
          })
          .then(response => {
              Functions.enableBtn('Delete', btn);
              
              if(response.data.data) {
                  status.innerHTML = `<p style='color:green'>Ads deleted</p>`;
                  window.location.reload();
              } else {
                  status.innerHTML = `<p style='color:red'>Ads not deleted. Try again.</p>`;
              }
          }).
          catch( (error) => {
            console.log(error);
            Functions.enableBtn('Delete', btn);
            status.innerHTML = "<p style='color:red'> Invalid request </p>";
          });
        }
      }
    });      
  }

  useEffect( () => {
  },[]);


  return (
    <tr>
        <td>{props.serial}</td>
        <td>
          <img src={props.picture} width="150px" className="img-responsive img-thumbnail"/>
        </td>
        <td><Link to={product_link} style={{color:"#17A2B8"}}>{props.title}</Link></td>
        <td><Link to={category_link} style={{color:"#17A2B8"}}>{props.category}</Link></td>
        <td>
          {Functions.isInt(props.price) ?
              <span>&#8358;{Functions.formatPrice(props.price)}</span> : 
              <span>{props.price}</span>
          }
        </td>
        <td><Link to={seller_link} style={{color:"#17A2B8"}}>{props.seller}</Link></td>
        <td>
          <div id={delete_status} align="center"></div>
          <Button active color="danger" size="sm" aria-pressed="true" id={delete_btn} onClick={deleteProduct.bind(this, props.id)}><i className="fa fa-trash"></i> Delete</Button>
        </td>
    </tr>
    );
}
