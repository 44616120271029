import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAlignLeft, faArrowCircleLeft, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { Navbar, Button, NavbarToggler, Collapse, Nav, NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import Functions from '../../utils/Functions';

export default props => {

  const [isOpen, setOpen] = useState(true)
  const toggle = () => setOpen(!isOpen)
  
  return (
    <Navbar color="light" light className="navbar shadow-sm p-3 mb-5 bg-white rounded" expand="md">
      <Button color="primary" onClick={Functions.goBack.bind(this)}>
        <FontAwesomeIcon icon={faArrowLeft}/>
      </Button> &nbsp; &nbsp; &nbsp; &nbsp; 
      <Button color="primary" onClick={props.toggle}>
        <FontAwesomeIcon icon={faAlignLeft}/>
      </Button>
      &nbsp; &nbsp; <h4 style={{textAlign: 'center'}}> Bineza </h4>
    </Navbar>
  );
}
