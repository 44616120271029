import React, { useState, useEffect, lazy, Suspense } from 'react';
import { BrowserRouter as Router, Route, Switch, Link, withRouter } from 'react-router-dom';
import {
  Card,
  CardBody,
  Col,
  Spinner,
  Row,
  Table,
} from 'reactstrap';
import axios from 'axios';
import Functions from '../../utils/Functions';
import { currentDate } from '../../utils/Dates';
import { serverEndPoint, webTitle } from '../../utils/Helpers';
import TheUser from './institutions/TheUser';
import TheProduct from './products/TheProduct';
const cookie = Functions.getCookie('bineza_admin_token');
        
export default function Dashboard() {
  
  let [values, setValues] = React.useState({
    count_products: 0,
    count_users: 0,
    count_customers: 0,
    count_sellers: 0,
    highest_ads: '',
    count_highest_ads: '',
    least_ads: '',
    count_least_ads: '',
    highest_users: '',
    count_highest_users: '',
    least_users: '',
    count_least_users: '',
    highest_sellers: '',
    count_highest_sellers: '',
    least_sellers: '',
    count_least_sellers: '',
    highest_customers: '',
    count_highest_customers: '',
    least_customers: '',
    count_least_customers: '',
    loaded: false,
    analyticsLoaded: false,
  });

  let [products, setProducts] = React.useState([]);
  let [users, setUsers] = React.useState([]);

  async function getDashboard() {

    await axios({
        method: 'get',
        url: `${serverEndPoint}dashboard`,
        headers : {
          'Content-Type' : 'application/json',
          'Accept' : 'application/json',
          'x-access-token' : cookie
        }
    })
    .then(response => {
        
        const details = response.data.data;
        
        if(details) {

          setProducts(details.recent_products);
          setUsers(details.recent_users);
          
          setValues(values => ({
            ...values, 
            count_customers : details.count_customers,
            count_sellers : details.count_sellers,
            count_products : details.count_products,
            count_users : details.count_customers + details.count_sellers,
            loaded: true, 
          }));
        }  

    }).
    catch( (error) => {
      console.log(error);
    });
  }

  async function updateAnalytics() {

    await axios({
        method: 'get',
        url: `${serverEndPoint}update-analytics`,
        headers : {
          'Content-Type' : 'application/json',
          'Accept' : 'application/json',
          'x-access-token' : cookie
        }
    })
    .then(response => {
        
    }).
    catch( (error) => {
      console.log(error);
    }); 
  }

  async function getAnalytics() {

    await axios({
        method: 'get',
        url: `${serverEndPoint}analytics`,
        headers : {
          'Content-Type' : 'application/json',
          'Accept' : 'application/json',
          'x-access-token' : cookie
        }
    })
    .then(response => {
        
        const details = response.data.data;
        
        if(details) {

          setValues(values => ({
            ...values, 
            highest_ads: details.highest_products.name,
            count_highest_ads: details.highest_products.ads,
            least_ads: details.least_products.name,
            count_least_ads: details.least_products.ads,
            highest_users: details.highest_users.name,
            count_highest_users: details.highest_users.users,
            least_users: details.least_users.name,
            count_least_users: details.least_users.users,
            highest_sellers: details.highest_sellers.name,
            count_highest_sellers: details.highest_sellers.sellers,
            least_sellers: details.least_sellers.name,
            count_least_sellers: details.least_sellers.sellers,
            highest_customers: details.highest_customers.name,
            count_highest_customers: details.highest_customers.customers,
            least_customers: details.least_customers.name,
            count_least_customers: details.least_customers.customers,
            analyticsLoaded: true, 
          }));
        }  
      }).
      catch( (error) => {
        console.log(error);
      });
  }

  useEffect( () => {
    getDashboard();
    updateAnalytics();
    getAnalytics();
    document.title = webTitle+" Dashboard"
  }, []);


  return (
      <div>
        <Row>
          <Col xs="6" sm="4" lg="4">
            <Link to={"/customers"} style={{textDecoration: 'none'}}>
              <Card className="text-white bg-info">
                <CardBody className="pb-0">
                  <h2>{values.count_customers}</h2>
                  <div>Customers</div>
                </CardBody>
                <div className="chart-wrapper mx-3" style={{ height: '70px' }}></div>
              </Card> <br/><br/>
            </Link>
          </Col>

          <Col xs="6" sm="4" lg="4">
            <Link to={"/sellers"} style={{textDecoration: 'none'}}>
              <Card className="text-white bg-warning">
                <CardBody className="pb-0">
                  <h2>{values.count_sellers}</h2>
                  <div>Sellers</div>
                </CardBody>
                <div className="chart-wrapper mx-3" style={{ height: '70px' }}></div>
              </Card> <br/><br/>
            </Link>
          </Col>

          <Col xs="12" sm="4" lg="4">
            <Link to={"/ads"} style={{textDecoration: 'none'}}>
              <Card className="text-white bg-danger">
                <CardBody className="pb-0">
                  <h2>{values.count_products}</h2>
                  <div>Ads</div>
                </CardBody>
                <div className="chart-wrapper mx-3" style={{ height: '70px' }}></div>
              </Card> <br/><br/>
            </Link>
          </Col>
        
        </Row>

        <h2> Analytics </h2>
        
        <div>
          
          <Row>

            <Col xs="6" sm="6" lg="3">
              <Card className="bg-default">
                <CardBody className="pb-0">
                  <div>Highest Ads</div> <br/>
                  <h5>{values.highest_ads}</h5>
                  <h2>{values.count_highest_ads}</h2>
                </CardBody>
                <div className="chart-wrapper mx-3" style={{ height: '50px' }}></div>
              </Card> <br/>
            </Col>

            <Col xs="6" sm="6" lg="3">
              <Card className="bg-default">
                <CardBody className="pb-0">
                  <div>Least Ads</div> <br/>
                  <h5>{values.least_ads}</h5>
                  <h2>{values.count_least_ads}</h2>
                </CardBody>
                <div className="chart-wrapper mx-3" style={{ height: '50px' }}></div>
              </Card> <br/>
            </Col>

            <Col xs="6" sm="6" lg="3">
              <Card className="bg-default">
                <CardBody className="pb-0">
                  <div>Highest Users</div> <br/>
                  <h5>{values.highest_users}</h5>
                  <h2>{values.count_highest_users}</h2>
                </CardBody>
                <div className="chart-wrapper mx-3" style={{ height: '50px' }}></div>
              </Card> <br/>
            </Col>

            <Col xs="6" sm="6" lg="3">
              <Card className="bg-default">
                <CardBody className="pb-0">
                  <div>Least Users</div> <br/>
                  <h5>{values.least_users}</h5>
                  <h2>{values.count_least_users}</h2>
                </CardBody>
                <div className="chart-wrapper mx-3" style={{ height: '50px' }}></div>
              </Card> <br/>
            </Col>

          </Row>
        
          <br/>
          
          <Row>
            
            <Col xs="6" sm="6" lg="3">
              <Card className="bg-default">
                <CardBody className="pb-0">
                  <div>Highest Sellers</div> <br/>
                  <h5>{values.highest_sellers}</h5>
                  <h2>{values.count_highest_sellers}</h2>
                </CardBody>
                <div className="chart-wrapper mx-3" style={{ height: '50px' }}></div>
              </Card>  <br/>
            </Col>

            <Col xs="6" sm="6" lg="3">
              <Card className="bg-default">
                <CardBody className="pb-0">
                  <div>Least Sellers</div> <br/>
                  <h5>{values.least_sellers}</h5>
                  <h2>{values.count_least_sellers}</h2>
                </CardBody>
                <div className="chart-wrapper mx-3" style={{ height: '50px' }}></div>
              </Card>  <br/>
            </Col>

            <Col xs="6" sm="6" lg="3">
              <Card className="bg-default">
                <CardBody className="pb-0">
                  <div>Highest Customers</div> <br/>
                  <h5>{values.highest_customers}</h5>
                  <h2>{values.count_highest_customers}</h2>
                </CardBody>
                <div className="chart-wrapper mx-3" style={{ height: '50px' }}></div>
              </Card>  <br/>
            </Col>
            
            <Col xs="6" sm="6" lg="3">
              <Card className="bg-default">
                <CardBody className="pb-0">
                  <div>Least Customers</div> <br/>
                  <h5>{values.least_customers}</h5>
                  <h2>{values.count_least_customers}</h2>
                </CardBody>
                <div className="chart-wrapper mx-3" style={{ height: '50px' }}></div>
              </Card>  <br/>
            </Col>
          </Row> 
        </div> 

        <Row>
            <Col Col xs="12" sm="12" lg="12">
            <br/><br/>
            <h3> Recent Ads </h3>
                
              <Card>
                <CardBody>    
                  
                    {!values.loaded?
                        <div align="center">
                            Please wait..
                        </div> :
                        
                        <div>
                        
                          {values.count_products == 0 ?

                              <div align='center'> No ads yet! </div> :

                              <Table responsive bordered className="table-outline mb-0 d-sm-table">
                                <thead className="thead-light">
                                  <tr>
                                      <th>S/N</th>
                                      <th>Image</th>
                                      <th>Title</th>
                                      <th>Category</th>
                                      <th>Price</th>
                                      <th>Seller</th>
                                      <th>Actions</th>
                                  </tr>
                                </thead>
                                
                                <tbody>
                                  {products.map( (product, index) => 
                                      <TheProduct
                                        key={index} 
                                        serial={index+=1}
                                        id={product.id}
                                        picture={product.picture}
                                        title={Functions.capitalizeWord(product.name)}
                                        price={product.price}
                                        category_id={product.category_id}
                                        category={product.category}
                                        seller_id={product.seller_id}
                                        seller={product.seller_name}
                                      />
                                    )
                                  }
                                </tbody>
                              </Table>
                            }
                      </div>
                    }
                  </CardBody>
                </Card>
            </Col>

            <Col Col xs="12" sm="12" lg="12">
            <br/><br/>
            <h3>Recent Users </h3>
                  
            <Card>
              <CardBody>
                  
                  {!values.loaded?
                      <div align="center">
                          {/* <Spinner type="grow" style={{ width: '3rem', height: '3rem' }} color="primary" /> */}
                          Please wait..
                      </div> :
                      
                      <div>
                      
                        {values.count_users == 0?
                        
                          "No user yet!" :
                          
                          <Table responsive bordered className="table-outline mb-0 d-sm-table">
                            <thead className="thead-light">
                              <tr>
                                  <th className="text-center">S/N</th>
                                  <th className="text-center">Image</th>
                                  <th className="text-center">Fullname</th>
                                  <th className="text-center">Type</th>
                                  <th className="text-center">Email</th>
                                  <th className="text-center">Phone</th>
                                  <th className="text-center">Last seen</th>
                                  <th className="text-center">Reg on</th>
                              </tr>
                            </thead>
                            
                            <tbody>
                              {users.map( (user, index) => 
                                  <TheUser 
                                    key={index} 
                                    serial={index+=1}
                                    id={user.id}
                                    firstname={Functions.capitalizeWord(user.firstname)}
                                    lastname={Functions.capitalizeWord(user.lastname)}
                                    type={user.type}
                                    business={user.business}
                                    email={user.email}
                                    phone={user.phone}
                                    city={user.city}
                                    state={user.state}
                                    address={user.address}
                                    picture_url={user.picture_url}
                                    last_seen={user.last_seen}
                                    reg_on={user.created_at}
                                  />
                                )
                              }
                            </tbody>
                          </Table>
                        }
                    </div>
                  }
                </CardBody>
              </Card>
            </Col>
          </Row>
          <br/><br/>
      </div>
    );
}
