import React from 'react';
import classNames from 'classnames';
import { Container } from 'reactstrap';
import NavBar from './Navbar';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

const Dashboard = React.lazy(() => import ('../admin/Dashboard'));
const Categories = React.lazy(() => import ('../admin/products/categories/Categories'));
const CategoryProducts = React.lazy(() => import ('../admin/products/categories/CategoryProducts'));
const Institutions = React.lazy(() => import ('../admin/institutions/Institutions'));
const InstitutionCustomers = React.lazy(() => import ('../admin/institutions/users/InstitutionCustomers'));
const InstitutionSellers = React.lazy(() => import ('../admin/institutions/users/InstitutionSellers'));
const InstitutionUsers = React.lazy(() => import ('../admin/institutions/users/InstitutionUsers'));
const InstitutionProducts = React.lazy(() => import ('../admin/institutions/products/InstitutionProducts'));
const Products = React.lazy(() => import('../admin/products/Products'));
const ViewProduct = React.lazy(() => import('../admin/products/ViewProduct'));
const ProductReviews = React.lazy(() => import('../admin/products/reviews/BookReviews'));
const Customers = React.lazy(() => import('../admin/users/customers/Customers'));
const Sellers = React.lazy(() => import('../admin/users/sellers/Sellers'));
const ViewCustomer = React.lazy(() => import('../admin/users/customers/ViewCustomer'));
const ViewSeller = React.lazy(() => import('../admin/users/sellers/ViewSeller'));
const ChangePassword = React.lazy(() => import  ('../admin/ChangePassword'));
const Logout = React.lazy(() => import ('../admin/Logout'));

const loading = () => <div className="animated fadeIn pt-3 text-center">Loading...</div>;

export default props => (
    <Container fluid className={classNames('content', {'is-open': props.isOpen})}>
        <NavBar toggle={props.toggle}/>
          <React.Suspense fallback={loading()}>
            <Switch>
              <Route path="/dashboard" component={Dashboard}/>
              <Route path="/categories/details/:category_id" component={CategoryProducts}/>
              <Route path="/categories" component={Categories}/>
              <Route path="/ads/details/:product_id" component={ViewProduct}/>
              <Route path="/ads/reviews/:product_id" component={ProductReviews}/>
              <Route path="/ads" component={Products}/>
              <Route path="/sellers" component={Sellers}/>
              <Route path="/seller/details/:seller_id" component={ViewSeller}/>
              <Route path="/customers" component={Customers} />
              <Route path="/customer/details/:customer_id" component={ViewCustomer}/>
              <Route path="/institutions/customers/:institution_id" component={InstitutionCustomers} />
              <Route path="/institutions/sellers/:institution_id" component={InstitutionSellers} />
              <Route path="/institutions/users/:institution_id" component={InstitutionUsers} />
              <Route path="/institutions/ads/:institution_id" component={InstitutionProducts} />
              <Route path="/institutions" component={Institutions} />
              <Route path="/subscription-plans" component={Customers} />
              <Route path="/change-password" component={ChangePassword} />
              <Route path="/logout" component={Logout} />
            </Switch>
          </React.Suspense>
    </Container>
)
