import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserFriends, faDatabase, faKey, faPowerOff, faUsers, faAd, faBars, faSchool } from '@fortawesome/free-solid-svg-icons';
import { NavItem, NavLink, Nav } from 'reactstrap';
import classNames from 'classnames';
import {Link} from 'react-router-dom';
import swal from 'sweetalert';
import Functions from '../../utils/Functions';
import { adminToken } from '../../utils/Helpers';

export default function SideBar(props) {
  
  async function logout() {
    swal({
      title: "",
      text: 'Are you sure you want to logout?',
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
    .then( async (response) => {
        if(response) {
            await Functions.deleteCookie(adminToken);
            window.location="/";
        }
    })
    .catch( (error) => {
      alert("Logout not successful");
    });
  }
  return (
    <div className={classNames('sidebar', {'is-open': props.isOpen})} style={{backgroundColor: '#0373B4'}}>
      <div className="sidebar-header" style={{backgroundColor: '#0373B4', margin:20}}>
        <span color="info" onClick={props.toggle} style={{color: '#fff'}}>&times;</span>
          <div align="center"><img src={require('../images/logo-white.png')} style={{width:100}}/></div><hr/>
      </div>
      <div className="side-menu">
        <Nav vertical className="list-unstyled pb-3">
          <NavItem style={{marginBottom:10}} onClick={props.toggle}>
            <NavLink tag={Link} to={'/dashboard'}>
              <FontAwesomeIcon icon={faDatabase} className="mr-2"/>Dashboard
            </NavLink>
          </NavItem>
          <NavItem style={{marginBottom:10}} onClick={props.toggle}>
            <NavLink tag={Link} to={'/ads'}>
              <FontAwesomeIcon icon={faAd} className="mr-2"/>Ads
            </NavLink>
          </NavItem>
          <NavItem style={{marginBottom:10}} onClick={props.toggle}>
            <NavLink tag={Link} to={'/customers'}>
              <FontAwesomeIcon icon={faUsers} className="mr-2"/>Customers
            </NavLink>
          </NavItem>
          <NavItem style={{marginBottom:10}} onClick={props.toggle}>
            <NavLink tag={Link} to={'/sellers'}>
              <FontAwesomeIcon icon={faUserFriends} className="mr-2"/>Sellers
            </NavLink>
          </NavItem>
          <NavItem style={{marginBottom:10}} onClick={props.toggle}>
            <NavLink tag={Link} to={'/categories'}>
              <FontAwesomeIcon icon={faBars} className="mr-2"/>Categories
            </NavLink>
          </NavItem>
          <NavItem style={{marginBottom:10}} onClick={props.toggle}>
            <NavLink tag={Link} to={'/institutions'}>
              <FontAwesomeIcon icon={faSchool} className="mr-2"/>Institutions
            </NavLink>
          </NavItem>
          <NavItem style={{marginBottom:10}} onClick={props.toggle}>
            <NavLink tag={Link} to={'/change-password'}>
              <FontAwesomeIcon icon={faKey} className="mr-2"/>Change Password
            </NavLink>
          </NavItem>
          <NavItem style={{marginBottom:10}} onClick={props.toggle}>
            <NavLink tag={Link} onClick={logout}>
              <FontAwesomeIcon icon={faPowerOff} className="mr-2"/>Logout
            </NavLink>
          </NavItem>
        </Nav>        
      </div>
    </div>
  );
}